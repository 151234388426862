<template>
  <v-dialog
    value="true"
    max-width="500"
    @click:outside="close"
  >
    <v-card>
      <v-card-title> {{ $t('paylinkFor') }} #{{ this.order.orderId }}</v-card-title>
      <v-card-subtitle>{{ $t('configurePaylinkOptionsCopyLinkOrSendDirectly') }}</v-card-subtitle>
      <v-container
        fluid
        class="py-0 px-6"
      >
        <v-row>
          <v-col
            cols="12"
          >
            <v-row
              dense
            >
              <v-col
                cols="12"
                class="pb-3"
              >
                <v-row
                  align="center"
                >
                  <v-col
                    class="py-0 grow pr-0"
                    cols="auto"
                  >
                    <v-text-field
                      hide-details
                      :label="$t('link')"
                      dense
                      outlined
                      v-model="url"
                      disabled
                    />
                  </v-col>
                  <v-col
                    class="py-0 px-2"
                    cols="auto"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs}">
                        <v-btn
                          icon
                          :disabled="!url"
                          @click="copyUrlToClipboard(url)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('copyLink') }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                class="pb-3"
              >
                <v-select
                  dense
                  outlined
                  hide-details
                  v-model="channelUUID"
                  :items="activeChannels"
                  item-text="label"
                  item-value="uuid"
                  :label="$t('channel')+'*'"
                />
              </v-col>
              <v-col
                cols="12"
                class="pb-3"
              >
                <v-select
                  dense
                  outlined
                  hide-details
                  v-model="language"
                  :items="languages"
                  item-value="key"
                  item-text="name"
                  :label="$t('language')"
                />
              </v-col>
              <v-col
                cols="12"
                class="pb-3"
              >
                <v-text-field
                  dense
                  outlined
                  v-model="message"
                  :label="$t('messageToCustomer')"
                  hide-details
                />
              </v-col>
              <v-col
                v-if="messengerMethod === 'email'"
                cols="12"
                class="pb-3"
              >
                <v-text-field
                  dense
                  outlined
                  v-model="customerEmail"
                  :label="$t('email') + '*'"
                  validate-on-blur
                  :rules="[rules.email]"
                />
              </v-col>
              <v-col
                v-if="messengerMethod === 'sms'"
                cols="12"
                class="pb-3"
              >
                <v-text-field
                  dense
                  outlined
                  v-model="customerPhone"
                  :label="$t('phone') + '*'"
                  validate-on-blur
                  :rules="[rules.intlPhone]"
                />
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col
                    cols="4"
                    class="pb-3"
                  >
                    <v-btn
                      outlined
                      large
                      block
                      color="red"
                      @click="close"
                    >
                      {{ $t('close') }}
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="3"
                    class="mr-6 pl-6"
                  >
                    <v-btn-toggle
                      v-model="messengerMethod"
                      mandatory
                    >
                      <v-btn
                        value="email"
                        outlined
                      >
                        <v-icon>
                          mdi-email
                        </v-icon>
                      </v-btn>
                      <v-btn
                        value="sms"
                        outlined
                      >
                        <v-icon>
                          mdi-cellphone
                        </v-icon>
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                  <v-col
                    cols="4"
                    class="pb-3"
                    v-if="messengerMethod === 'email'"
                  >
                    <v-tooltip
                      top
                      :disabled="valuesAreValid"
                    >
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-btn
                            outlined
                            large
                            block
                            @click="sendPaylinkEmail"
                            :disabled="!valuesAreValid"
                          >
                            {{ $t('sendPaylink') }}
                          </v-btn>
                        </div>
                      </template>
                      {{ this.channelUUID ? $t('validEmailRequired') : $t('channelRequired') }}
                    </v-tooltip>
                  </v-col>
                  <v-col
                    cols="4"
                    class="pb-3"
                    v-if="messengerMethod === 'sms'"
                  >
                    <v-tooltip
                      top
                      :disabled="valuesAreValid"
                    >
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-btn
                            outlined
                            large
                            block
                            @click="sendPaylinkSMS"
                            :disabled="!valuesAreValid"
                          >
                            {{ $t('sendPaylink') }}
                          </v-btn>
                        </div>
                      </template>
                      {{ this.channel ? $t('validPhoneNumberRequired') : $t('channelRequired') }}
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
    
    <script>
    
    export default {
      name: 'OrderPaylinkCard',
      components: {
      },
      props: {
        order: {
          type: Object,
          default: () => {}
        }
      },
      data() {
        return {
          channelUUID: null,
          language: null,
          customerEmail: null,
          customerPhone: null, 
          messengerMethod: 'email'
        }
      },
      methods: {
        sendPaylinkEmail() {     
          this.$store.dispatch('sendPaylinkEmail', {orderUUID: this.order.uuid, channelUUID: this.channelUUID, email: this.customerEmail, language: this.language, message: this.message}).then(res => {
          this.$emit('close')
          if(res) {
            this.$store.commit('updateActionSuccess', {
              message: this.$t('success'), 
              subMessage: this.$t('paylinkHasBeenSentToEmail', {email: this.customerEmail})
            })
          } else {
            this.$store.commit('updateActionError', {
              message: this.$t('error'), 
              subMessage: this.$t('somethingWentWrong')
            })
          }
        }).catch(() => {
          this.$store.commit('updateActionError', {
            message: this.$t('error'), 
            subMessage: this.$t('somethingWentWrong')
          })
        })
      },
      sendPaylinkSMS() {     
          this.$store.dispatch('sendPaylinkSMS', {orderUUID: this.order.uuid, channelUUID: this.channelUUID, phone: this.customerPhone, language: this.language, message: this.message}).then(res => {
          this.$emit('close')
          if(res) {
            this.$store.commit('updateActionSuccess', {
              message: this.$t('success'), 
              subMessage: this.$t('paylinkHasBeenSentToPhone', {phone: this.customerPhone})
            })
          } else {
            this.$store.commit('updateActionError', {
              message: this.$t('error'), 
              subMessage: this.$t('somethingWentWrong')
            })
          }
        }).catch(() => {
          this.$store.commit('updateActionError', {
            message: this.$t('error'), 
            subMessage: this.$t('somethingWentWrong')
          })
        })
      },
        close() {
            this.$emit('close')
        },
        async copyUrlToClipboard(url) {
          if(typeof navigator !== 'undefined' && navigator !== null) {
            if(typeof navigator.clipboard !== 'undefined' && navigator.clipboard !== null) {
              await navigator.clipboard.writeText(url)
            }
          }
        }
      },
      computed: {
        valuesAreValid() { 
          if(this.rules.email(this.customerEmail) !== true && this.messengerMethod === 'email') {            
            return false
          }

          if(this.rules.intlPhone(this.customerPhone) !== true && this.messengerMethod === 'sms') {            
            return false
          }
          
          if(!this.channelUUID) {
            return false
          }

          return true 
        },
        rules() {
          return this.$store.getters.rules
        },  
        languages() {
          return this.$store.state.languages
        },
        user() {
          return this.$store.state.user
        },
        url() {
          if(typeof this.order === 'undefined' || this.order === null) {
            return null
          }
          
          if(!this.channelUUID) {
            return null
          }

          return 'https://pay.salescloud.is/?uuid=' + this.order.uuid + '&channel=' + this.channelUUID  + '&lang=' + this.language
        },
        activeChannels() {
          return this.$store.state.channels.filter(channel => channel.active)
        },
      },
      created() { 
        this.language = this.user.language
        if(this.order.channel) {
          const channel = this.activeChannels.find(channel => channel.label === this.order.channel.label)
          this.channelUUID = channel.uuid
        }
        if(this.order.customer) {
          if(this.order.customer.email) {
            this.customerEmail = this.order.customer.email
          }
          if(this.order.customer.phone) {
            this.customerPhone = this.order.customer.phone.length <= 7 ? '+354' + this.order.customer.phone : this.order.customer.phone
          }
        }
      }
    }
    </script>
    